.mat-mdc-menu-panel {
  &.itds-dropdown-menu {
    max-height: 320px;
    border-radius: var(--itds-radius-4);
    background: var(--itds-neutral-50);
    box-shadow: 0 0 1px 0 rgb(9 30 66 / 31%), 0 8px 12px 0 rgb(9 30 66 / 15%);

    .mat-mdc-menu-content {
      padding: var(--itds-spacing-6);
    }
  }
}
